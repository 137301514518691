import axiosInstance from '../../plugins/axios'
import axios from '@/plugins/axios'

export default {
    namespaced: true,
    state: {
        posts: [],
        postsHashId: {}, // postsHashId[id] === indexOfPost
        showHelp: {
            videoPurposeCreate: true
        },
        dateCloseCompetition: null
    },
    getters: {
        getPosts: state => state.posts,
        getPostsOrdered: state => {
            return state.posts.sort((a, b) => parseInt(a.attributes.order) > parseInt(b.attributes.order) ? 1 : -1)
        },
        getShowHelpPath: (state, getters, rootState, rootGetters) => {
            return 'showHelp-' + rootGetters.getUserId;
        },
        getShowHelp: (state, getters) => {
            let showHelpSaved = localStorage.getItem(getters.getShowHelpPath);

            // console.log("showHelpSaved", showHelpSaved, getters.getShowHelpPath);

            if (showHelpSaved) {
                showHelpSaved = JSON.parse(showHelpSaved);
                /* console.log("getShowHelp", showHelpSaved);
                console.log("showHelp", JSON.stringify(Object.assign(state.showHelp, showHelpSaved))); */
            } else {
                showHelpSaved = {
                    videoPurposeCreate: true
                };
            }

            const newShowHelp = Object.assign(state.showHelp, showHelpSaved);
            return newShowHelp;
        },
        canShowVideoPurposeCreate: (state, getters) => getters.getShowHelp.videoPurposeCreate,
        getDateCloseCompetition: state => state.dateCloseCompetition
    },
    mutations: {
        setPosts(state, posts) {
            state.posts = posts
        },
        setShowVideoPurposeCreate(state, { showHelpPath, videoPurposeCreate }) {
            state.showHelp.videoPurposeCreate = videoPurposeCreate;
            localStorage.setItem(showHelpPath, JSON.stringify(state.showHelp));
        },
        setDateCloseCompetition(state, date) {
            state.dateCloseCompetition = date;
        }
    },
    actions: {
        async loadPosts({
            commit,
            state
        }) {
            try {
                if (state.posts.length) return state.posts

                const posts = await axiosInstance
                    .get('posts?sort=order')

                commit('setPosts', posts.data.data)

                return state.posts
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        },
        setShowVideoPurposeCreate({ state, getters, commit }, videoPurposeCreate) {
            const showHelpPath = getters.getShowHelpPath;
            commit('setShowVideoPurposeCreate', { videoPurposeCreate: videoPurposeCreate, showHelpPath })
        },
        async loadDateCloseCompetition({ commit, state }) {
            try {
                if (state.dateCloseCompetition instanceof Date) return;

                const configs = await axios
                    .get('configs?filter[name]=competition');

                // console.log('loadDateCloseCompetition ', configs?.data?.data);

                if (configs?.data?.data?.length) {
                    let options = configs.data.data[0].attributes?.options;
                    if (options) {
                        options = JSON.parse(options);
                        const dateCloseCompetition = options?.end ? new Date(options.end.replace(" ", "T")) : null;

                        commit('setDateCloseCompetition', dateCloseCompetition);
                    }
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }
    }
}
